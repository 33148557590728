import React from "react"
import PropTypes from "prop-types"
import ContentBlock from "../ContentBlock"

const PageTitle = ({
  title,
  description = false,
  className = "",
  container = true,
}) => (
  <ContentBlock condition={container}>
    <div className={`mt-2 lg:mt-10 mb-6 lg:mb-10 ${className}`}>
      <div className="max-w-4xl">
        <h2 className="text-yellow-500 leading-300 lg:leading-300 text-3xl lg:text-5xl font-300 lg:font-200">
          {title}
        </h2>
        {description ? (
          <p className="mt-2 text-gray-300 leading-500 text-base lg:text-xl font-300">
            {description}
          </p>
        ) : (
          false
        )}
      </div>
    </div>
  </ContentBlock>
)

PageTitle.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  container: PropTypes.bool,
}

export default PageTitle
