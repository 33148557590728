import React from "react"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import ContentBlock from "../components/ContentBlock"

const PagePrivacyPolicy = () => (
  <Layout>
    <PageTitle title="Privacy Policy"></PageTitle>
    <ContentBlock className="rich-text">
      <p>
        Welcome to Towbook! Towbook is provided by Extric LLC, DBA Towbook
        (collectively referred to herein as “we,” “us,” “our,” or “Towbook”). We
        respect your privacy and the importance of the information you entrust
        to us. With this in mind, we provide you this privacy policy (the
        “Policy”) to help you understand the kinds of information we may gather
        about you when you use our online and/or mobile services, website, and
        software provided by or in connection with the service (collectively the
        “<strong>Services</strong>”), how we may use and disclose the
        information, and how you can control, correct and/or update the
        information. By accessing or using the Services, you are accepting the
        policies and practices described in this Policy. Each time you visit or
        use the Services, you agree and expressly consent to our collection, use
        and disclosure of the information that you provide as described in this
        Policy. Any capitalized terms not defined herein shall have the meaning
        as set forth in the Terms of Service.{" "}
      </p>
      <p>
        Please note that this Policy does not apply to your use of third-party
        sites, services, or applications you may access through the Services. We
        encourage you to review the privacy policies of those third parties for
        more information regarding their privacy practices.{" "}
      </p>
      <h3>INFORMATION WE MAY COLLECT</h3>
      <h4>Information You Provide to Us</h4>
      <p>
        We collect information you provide directly to us when using the
        Services. For example, we collect information when you download our
        mobile application(s), register for our Services, sign up for an
        account, complete a form, participate in any interactive features of the
        Services, request customer support, or otherwise communicate with us.
        The types of information we may collect include your name, email
        address, mailing and billing address and payment information, and any
        other information you choose to provide. For purposes of registering and
        using the Services, we collect certain information that can identify
        you, such as your name and contact information (email, address and phone
        number) (“Personal Information”) and the specific service call such as a
        vehicle’s make, model, model year, license plate number and VIN number
        (“Vehicle Information”) . We will never sell, rent, trade or use
        Personal Information or Vehicle Information other than as needed to
        provide the Services requested by you. Towbook discloses Personal
        Information only to those of its employees, contractors and affiliated
        organizations that (i) need to know the information in order to process
        it on yours and our behalf, and (ii) that have agreed in writing to
        non-disclosure restrictions at least as strong as those herein. We also
        may collect device-specific information (such as your hardware model,
        operating system version, unique device identifiers, and mobile network
        information including phone number). We may associate your device
        identifiers or phone number with your account. Depending on the nature
        of your inquiry or activities on the Services, we may also ask for other
        information relevant to your use of the Services.{" "}
      </p>
      <p>
        You acknowledge and agree that communications, including phone calls or
        “chat” services, with Towbook or our third-party agents may be
        monitored, recorded and retained by Towbook. You consent to the
        monitoring and recording of all conversations between you and Towbook
        and you release us from and against any and all claims, liabilities and
        losses that may result from any such monitored and/or recorded
        conversations.{" "}
      </p>
      <h4>Information We Collect Automatically When You Use the Services</h4>
      <p>
        When you access or use the Services, we automatically collect
        information about you, including the following:{" "}
      </p>
      <ul>
        <li>
          <strong>Log Information:</strong>We log information about your use of
          the Services, including the type of browser you use, access times,
          pages viewed, your IP address, and the page you visited before
          navigating to the Services. Publicly available tools can sometimes
          provide the approximate location for IP addresses.
        </li>
        <li>
          <strong>Device Information:</strong> We collect information about the
          computer or mobile device you use to access the Services, including
          the hardware model, operating system and version, unique device
          identifiers, mobile network information, and in limited cases what
          apps are installed on your mobile device.
        </li>
        <li>
          <strong>
            Information Collected by Cookies and Other Tracking Technologies:
          </strong>{" "}
          We use various technologies to collect information, and this may
          include sending cookies to your computer or mobile device. Cookies are
          small data files stored in device memory that help us to improve the
          Services and your experience, see which areas and features of the
          Services are popular, and count visits. We may also collect
          information using web beacons (also known as “tracking pixels”). Web
          beacons are electronic images that may be used in the Services or
          emails and help deliver cookies, count visits, understand usage and
          campaign effectiveness, and determine whether an email has been opened
          and acted upon.
        </li>
      </ul>
      <p>
        We also may collect other types of information in the following ways
        when you use the Services:{" "}
      </p>
      <ul>
        <li>
          Details of how you used and interacted with the Services, such as your
          search queries and how you responded to certain questions.{" "}
        </li>
        <li>
          Device event information such as crashes, system activity, hardware
          settings, browser type, browser language, the date and time of your
          request and referral URL.{" "}
        </li>
        <li>
          Our server logs automatically collect information, such as your IP
          address, your browser type and language, and the date and time of your
          visit, which helps us track your movements around the Services and
          understand trends.{" "}
        </li>
      </ul>
      <h4>Information We Collect From Other Sources</h4>
      <p>
        We may also obtain information from other sources and combine that with
        information we collect through the Services. For example, we may collect
        information about you from third parties who provide services on our
        behalf, such as maintaining and monitoring usage of the Services and
        processing payment transactions. We also may receive information about
        you from the third parties with whom you interact through the Services.{" "}
      </p>
      <h3>USE OF INFORMATION</h3>
      <p>
        We may use information about you for various purposes, including the
        following:{" "}
      </p>
      <ul>
        <li>
          Provide, maintain, and improve the Services that you opt-in to
          receive, including, but not limited to, the following:
          <ul>
            <li>
              Digital dispatching – direct and indirect integrations with motor
              clubs and insurers where your information is shared and job
              opportunities are provided, including, but not limited to,
              <ul>
                <li>Information related to a particular job opportunity. </li>
                <li>
                  Driver availability (i.e. is your driver available to work).{" "}
                </li>
                <li>
                  Status events (i.e. your driver is en-route) and status times
                  (i.e. time driver arrived on-scene).{" "}
                </li>
                <li>
                  GPS / location data of driver before and during the various
                  status events.{" "}
                </li>
                <li>Photos of an accident, incident and/or vehicle. </li>
                <li>
                  Damage reports related to, and providing information about,
                  the vehicle including license plates, vin numbers, service
                  dates.{" "}
                </li>
                <li>Cancellations or changes to a job. </li>
                <li>
                  Information related to the outcome and handling of a
                  particular job.{" "}
                </li>
              </ul>
            </li>
            <li>
              GPS and Maps – integrations with service providers allowing
              Towbook to extract or receive real-time GPS coordinates for your
              driver who is using Towbook. The location data is collected in a
              form that identifies your driver’s device and/or your driver’s
              vehicle. Also allows you to look up addresses, map calls,
              calculate mileage and track locations of drivers, customers and
              jobs. We do not specifically provide data to GPS companies, but
              our integration includes the two-way exchange of data.
            </li>
            <li>
              Administrative – integrations that allow you to connect to
              financial accounting software, generate invoices, rates, accounts,
              account balances, charts, etc.
            </li>
          </ul>
        </li>
        <li>
          Provide and deliver the products and services you request, process
          transactions, and send you related information;{" "}
        </li>
        <li>
          Verify your identity and, if applicable, authorization for you to use
          the Services ;{" "}
        </li>
        <li>Process payment for Services; </li>
        <li>Facilitate use of the Services; </li>
        <li>Manage your account and your preferences; </li>
        <li>
          To prevent or address service, security, technical issues at your
          request in connection with customer support matters{" "}
        </li>
        <li>Respond to your comments, questions, and requests; </li>
        <li>Send you technical notices and other administrative messages; </li>
        <li>
          Communicate with you about products, services, offers, promotions,
          rewards, and events offered by us or others, and provide news and
          information we think will be of interest to you;{" "}
        </li>
        <li>
          Monitor and analyze trends, usage, and activities in connection with
          car crashes, car crash data and the Services;{" "}
        </li>
        <li>Conduct research, analysis, and surveys; </li>
        <li>
          Personalize and improve the Services and provide content or features
          that match user profiles or interests;{" "}
        </li>
        <li>Enforce our Terms; </li>
        <li>
          Link or combine with information we get from others in connection with
          the Services; and{" "}
        </li>
        <li>
          Carry out any other purpose for which the information was collected.{" "}
        </li>
      </ul>
      <p>
        This policy is not intended to place any limits on what we do with data
        that is aggregated and/or de-identified so it is no longer associated
        with an identifiable user of the Services. In other words, information
        about how you use the Services may be collected and combined with
        information about how others use the same Services, but no personally
        identifiable information will be included in the resulting data.
      </p>
      <h3>SHARING OF INFORMATION</h3>
      <p>
        We may share or transfer information about you as follows or as
        otherwise described in this Privacy Policy:
      </p>
      <ul>
        <li>
          With third party affiliates, contractors, and other service providers
          in connection with providing the Services;{" "}
        </li>
        <li>
          With affiliates, contractors, and other service providers who need
          access to such information to carry out work on our behalf;{" "}
        </li>
        <li>
          In response to a request for information if we believe disclosure is
          in accordance with any applicable law, regulation, or legal process,
          or as otherwise required by any applicable law, rule, or regulation;{" "}
        </li>
        <li>
          If we believe your actions are inconsistent with the spirit or
          language of our Terms of Service or policies, or to protect the
          rights, property, and safety of you, us, or others;{" "}
        </li>
        <li>
          In connection with, or during negotiations of, any merger, sale of our
          assets, financing, or acquisition of all or a portion of our business
          to another company;{" "}
        </li>
        <li>
          With your consent or at your direction, including if we notify you
          through the Services that certain information you provide will be
          shared in a particular manner and you provide this information.
        </li>
      </ul>
      <p>
        We may also share aggregated or de-identified information, which cannot
        reasonably be used to identify you.
      </p>
      <h3>ANALYTICS SERVICES</h3>
      <p>
        We may allow others to provide analytics services in connection with the
        Services, such as New Relic, BugSnag, Crashlytics, and Google Analytics.
        These entities may use cookies, web beacons, and other technologies to
        collect information about your use of the Services, including your IP
        address, web browser, pages viewed, time spent on pages, links clicked,
        and conversion information. We and others may use this information to,
        among other things, analyze and track data, determine the popularity of
        certain content, personalize the user experience, and better understand
        your activity.{" "}
      </p>
      <ul>
        <li>
          To learn more about New Relic, please visit{" "}
          <a
            href="https://newrelic.com/termsandconditions/privacy"
            target="_blank"
            rel="noreferrer"
          >
            https://newrelic.com/termsandconditions/privacy
          </a>
          .
        </li>
        <li>
          To learn more about BugSnag, please visit{" "}
          <a
            href="https://docs.bugsnag.com/legal/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            https://docs.bugsnag.com/legal/privacy-policy/
          </a>
          .
        </li>
        <li>
          To learn more about Crashlytics, please visit{" "}
          <a href="https://fabric.io/terms" target="_blank" rel="noreferrer">
            https://fabric.io/terms
          </a>
          .
        </li>
        <li>
          To learn more about Google Analytics, please visit{" "}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            https://policies.google.com/privacy
          </a>
          .
        </li>
      </ul>
      <h3>SECURITY</h3>
      <p>
        We work hard to protect your information and take appropriate
        commercially reasonable physical, electronic, and other security
        measures to help safeguard personal information from loss, unauthorized
        access, alteration, or disclosure regardless of how or where it is
        stored. Our security practices include: encrypting many of our services
        using SSL; verification for account access; and frequent review of
        information collection, storage and processing practices, including
        physical security measures, to guard against unauthorized access to
        systems. However, no internet or e-mail transmission is ever fully
        secure or error free.  In particular, e-mail sent to or from the
        Services may not be secure.  Therefore, you should take special care in
        deciding what information you send to us via e-mail.
      </p>
      <h3>PRIVACY POLICY CHANGES </h3>
      <p>
        Towbook reserves the right to change, modify, add, or remove portions of
        this Policy at any time and without prior notice, and any changes will
        become effective immediately upon being posted unless we advise you
        otherwise. However, we will not use your Personal Information in a way
        that is materially different than the uses described in this Policy
        without giving you an opportunity to opt out of such differing uses.
        Your continued use of the Services after this Policy has been amended
        shall be deemed to be your continued acceptance of the terms and
        conditions of the Policy, as amended. We encourage you to review this
        Policy regularly.{" "}
      </p>
      <h3>CALIFORNIA PRIVACY RIGHTS </h3>
      <p>
        California law permits users who are California residents to request and
        obtain from us once a year, free of charge, a list of the third parties
        to whom we have disclosed their personal information (if any) for direct
        marketing purposes in the prior calendar year, as well as the type of
        personal information disclosed to those parties. If you are a California
        resident and would like to request this information, please submit your
        request in an email to{" "}
        <a href="mailto:support@towbook.com">support@towbook.com</a>.{" "}
      </p>
      <p>
        Towbook shall comply with the California Consumer Privacy Act of 2018,
        Cal. Civil Code § 1798.100 et seq., (“CCPA”), which may be amended from
        time to time. Per the terms of the CCPA, Towbook is a Service Provider
        and not a Third Party as described in the CCPA. Therefore, Towbook shall
        not:
      </p>
      <ol className="spaced list-lower-alpha">
        <li>Sell the Personal Information. </li>
        <li>
          Retain, use, or disclose the Personal Information for any purpose
          other than providing the Services or for a Business Purpose.
          Specifically, Company shall not retain, use, or disclose the Personal
          Information for a Commercial Purpose.{" "}
        </li>
        <li>
          Retain, use, or disclose the Personal Information outside of the
          direct business relationship between Towbook and its Users.{" "}
        </li>
      </ol>
      <p>
        Notwithstanding anything in the Terms of Service and End User License
        Agreement or any related order form or other document, the parties
        acknowledge and agree that a User’s provision of Personal Information is
        not part of and explicitly excluded from the exchange of consideration,
        or any other thing of value, between the parties.{" "}
      </p>
      <h3>CHILDREN UNDER THE AGE OF 13 </h3>
      <p>
        The Services are intended for users who are eighteen (18) years of age
        and older.  If you are under the age of 13, you are not permitted to
        submit any Personal Information to us.  If you believe we might have any
        information from or about a child under 13, please contact us at{" "}
        <a href="mailto:support@towbook.com">support@towbook.com</a>.
      </p>
      <h3>RETENTION</h3>
      <p>
        We will retain your Personal Information for the period of time that is
        necessary to fulfil the original purposes for which it has been
        collected. Please keep in mind that, in certain cases, a longer
        retention period may be required or permitted by law or to allow us to
        pursue our business interests, conduct audits, comply with our legal
        obligations, enforce our agreements or resolve any dispute
      </p>
      <ul>
        <li>Time needed to provide you with our Services.</li>
        <li>
          Whether your account with us is active. You may contact us to make
          your account inactive at any time.
        </li>
        <li>
          Legal, contractual, or similar obligations to retain your data, such
          as mandatory data retention laws in the applicable jurisdiction,
          government orders to preserve data relevant to an investigation, or
          data that must be retained for the purposes of contract or litigation.
        </li>
      </ul>
      <p>
        Please note that we are not responsible for storing any information that
        you provide to us or for any content or information that we provide to
        you. You are solely responsible for retaining backup files of any
        information and content that you provide or receive in connection with
        the Services.
      </p>
      <h3>TRANSFER OF PERSONAL INFORMATION; INTERNATIONAL VISITORS </h3>
      <p>
        The Services are provided from the United States.  If you are located
        outside of the United States, any information you provide to us may be
        transferred out of your country and into the United States. Personally
        identifiable information collected through the Services may be stored
        and processed in the United States or, if and as applicable for
        international Users, any other country in which Towbook or its
        affiliates, subsidiaries or service providers maintain facilities. If
        your Personal Information is transferred to a country other than your
        home country, we will take measures to protect it with appropriate
        contract clauses or other applicable safeguards.  If you are an
        international visitor, you acknowledge that by providing your Personal
        Information, you are: (a) permitting the transfer of your Personal
        Information to the United States which may not have the same data
        protection laws as the country in which you reside; and (b) permitting
        the use of your Personal Information in accordance with this Privacy
        Policy.{" "}
      </p>
      <h3>OPTIONS</h3>
      <h4>Your Information </h4>
      <p>
        Unless legal restrictions apply, you have the right to access the
        information we hold about you free of charge. You may update, correct or
        delete your information at any time by contacting us at{" "}
        <a href="mailto:support@towbook.com">support@towbook.com</a>. To avoid
        delays in obtaining your information, please provide sufficient detail
        to permit us to identify you and the specific information that you are
        requesting. We will respond to your request within 30 days of receipt,
        unless we inform you that it will take longer, as permitted by law under
        certain circumstances. Please note that there may be instances where
        access may be restricted as permitted or required by law. We will advise
        you of the reasons for restricting access subject to any legal or
        regulatory limitations. In addition, please note that we may retain
        certain information as required by law or for legitimate business
        purposes. We may also retain cached or archived copies of information
        about you for a certain period. Please note that we are not responsible
        for storing any information that you provide to us or a third party or
        for any content or information that we or a third party provide to you.
        You are solely responsible for retaining backup files of any information
        and content that you provide or receive in connection with the Services.{" "}
      </p>
      <h4>Promotional Communications </h4>
      <p>
        You may opt out of receiving promotional communications from us by
        following the instructions in those communications or by emailing us at
        support@towbook.com. If you opt out, we may still send you
        non-promotional communications, such as messages about your account or
        our ongoing business relations.
      </p>
      <h4>Non-Discrimination</h4>
      <p>
        We will not discriminate against you because you elect to exercise any
        of the rights related to your Personal Information, including but not
        limited to:
      </p>
      <ul>
        <li>Denying you products or services;</li>
        <li>
          Charging you different prices or rates for Services, including through
          the use of discounts or other benefits or imposing penalties on you;
        </li>
        <li>Providing a different level or quality of Services to you; or</li>
        <li>
          Suggesting that you will receive a different price or rate for
          Services or a different level or quality of Services.
        </li>
      </ul>
      <h3>HOW TO CONTACT US</h3>
      <p>
        If you have any questions about this Policy, or our information
        practices, please contact us by email at{" "}
        <a href="mailto:support@towbook.com">support@towbook.com</a>.
      </p>
      <div className="block px-4 py-3 my-8 text-sm text-center text-gray-100 bg-gray-600 rounded-lg sm:text-base font-500">
        Last updated on January 3, 2023
      </div>
    </ContentBlock>
  </Layout>
)

export default PagePrivacyPolicy
